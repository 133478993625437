import moment from 'moment'

export default {
  methods: {
    getIPPortFormat: function (ip, port, osType = 'Windows') {
      let result = ip
      if (![null, undefined, 3389, '3389'].includes(port)) {
        port = parseInt(port)
        if (osType === 'Windows') {
          result += ':' + port
        } else if (osType === 'Linux') {
          result += ':' + (parseInt(port) + 1).toString()
        }
      }
      return result
    },
    currencyFormatUSD (moneyNumber, locale = 'en-US', currency = 'USD') {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency
        }).format(moneyNumber)
    },
    currencyFormat (moneyNumber, locale = 'vi-VN', currency = 'VND') {
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency
      }).format(moneyNumber)
    },
    numberFormat (number, locale = 'vi-VN') {
      return new Intl.NumberFormat(locale).format(number)
    },
    getCurrentTime () {
      return moment().format('YYYY-MM-DD hh:mm:ss')
    },
    convertToISODate (time) {
      return moment(time).format('YYYY-MM-DD')
    },
    convertToISOTime (time) {
      return moment(time).format('YYYY-MM-DD hh:mm:ss')
    },
    momentAdd (currentTime, amount, unit) {
      return moment(currentTime).add(amount, unit).format('YYYY-MM-DD')
    },
    phoneNumberFormatter (phoneNumber) {
      if (!phoneNumber || isNaN(phoneNumber)) return `${phoneNumber}`
      if (typeof (phoneNumber) !== 'string') phoneNumber = phoneNumber.toString()
      if (phoneNumber.length === 10) {
        return phoneNumber.replace(/(\d{3})(\d{4})(\d{3})/, '$1-$2-$3')
      } else {
        return phoneNumber
      }
    },
    removeVietnameseDiacritics(str) {
      const accentsMap = [
        { base: 'a', letters: /[áàảãạăắằẳẵặâấầẩẫậ]/g },
        { base: 'e', letters: /[éèẻẽẹêếềểễệ]/g },
        { base: 'i', letters: /[íìỉĩị]/g },
        { base: 'o', letters: /[óòỏõọôốồổỗộơớờởỡợ]/g },
        { base: 'u', letters: /[úùủũụưứừửữự]/g },
        { base: 'y', letters: /[ýỳỷỹỵ]/g },
        { base: 'd', letters: /[đ]/g },
        { base: 'A', letters: /[ÁÀẢÃẠĂẮẰẲẴẶÂẤẦẨẪẬ]/g },
        { base: 'E', letters: /[ÉÈẺẼẸÊẾỀỂỄỆ]/g },
        { base: 'I', letters: /[ÍÌỈĨỊ]/g },
        { base: 'O', letters: /[ÓÒỎÕỌÔỐỒỔỖỘƠỚỜỞỠỢ]/g },
        { base: 'U', letters: /[ÚÙỦŨỤƯỨỪỬỮỰ]/g },
        { base: 'Y', letters: /[ÝỲỶỸỴ]/g },
        { base: 'D', letters: /[Đ]/g }
      ]

      accentsMap.forEach(accent => {
        str = str.replace(accent.letters, accent.base);
      })

      return str
    },
    sortByField(array, field, ascending = true) {
      return array.sort((a, b) => {
        if (a[field] > b[field]) {
          return ascending ? 1 : -1;
        } else if (a[field] < b[field]) {
          return ascending ? -1 : 1;
        } else {
          return 0;
        }
      })
    },
    shortContent(content, maxLength = 250) {
        if (content.length <= maxLength) {
            return content
        } else {
          return content.substring(0, maxLength) + '...'
        }
    }
  }
}
